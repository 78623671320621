import styles from "./ChatNotification.module.css";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function ChatNotification({ store }) {
	const { notification, activeChatId } = store;
	return notification && activeChatId ? (
		<div className={styles.wrapper}>
			<Notification {...notification} store={store} />
		</div>
	) : null;
}

export default observer(ChatNotification);

const Notification = observer(
	({ store, chatId, numChats, numMessages, text }) => {
		const [entered, setEntered] = useState(false);

		useEffect(() => setTimeout(() => setEntered(true), 10), []);

		const chat = chatId ? store.getChatById(chatId) : null;

		const handleClick = () => {
			if (chatId) {
				store.setActiveChat(chatId);
			} else {
				store.unsetActiveChat();
			}
		};

		return (
			<div
				onClick={handleClick}
				className={classnames(
					styles.notification,
					entered ? styles.entered : null
				)}
			>
				<IconMessage className={styles.icon} />
				{chat ? (
					<span className={styles.title}>{chat.title}</span>
				) : null}
				<span className={styles.text}>
					{notificationBody(numMessages, numChats, text)}
				</span>
			</div>
		);
	}
);

function notificationBody(numMessages, numChats, text) {
	if (numChats > 1) {
		return `${numMessages} messages from ${numChats} chats`;
	}
	if (numMessages > 1) {
		return `${numMessages} new messages`;
	}
	if (text) {
		return text; // TODO: limit
	}
	return "One unread message";
}

const IconMessage = ({ className }) => (
	<svg
		className={className}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z" />
	</svg>
);
