import styles from "./ChatSplitScreen.module.css";
import { ChatList } from "./ChatList";
import { Chat } from "./Chat";
import ChatNotification from "./ChatNotification";

import { observer } from "mobx-react-lite";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

export const ChatSplitScreen = observer(
    ({ store, appTitle, mobileScreenSize }) => {
        const [isMobile, setIsMobile] = useState(false);

        useEffect(() => {
            function resizeHandler() {
                setIsMobile(document.body.clientWidth <= mobileScreenSize);
            }
            resizeHandler();
            window.addEventListener("resize", resizeHandler);
            return () => window.removeEventListener("resize", resizeHandler);
        }, [mobileScreenSize]);

        return (
            <>
                {isMobile ? <ChatNotification store={store} /> : null}
                <ScreenContents
                    store={store}
                    isMobile={isMobile}
                    appTitle={appTitle}
                />
            </>
        );
    }
);
ChatSplitScreen.defaultProps = {
    mobileScreenSize: 800,
};

const ScreenContents = observer(({ store, isMobile, appTitle }) => (
    <div
        className={classnames(
            styles.screen,
            store.hasActiveChat ? styles.active : null
        )}
    >
        <div className={styles.screen__list}>
            <ChatList
                store={store}
                appTitle={appTitle}
                onChatSelect={(chatId) => store.setActiveChat(chatId)}
            />
        </div>
        <div className={styles.screen__chat}>
            <Chat store={store} showBackButton={isMobile} />
        </div>
    </div>
));
