import styles from "./Chat.module.css";
import ChatMessage from "./ChatMessage";
import ChatFooter from "./ChatFooter";
import {
    ChatStoreContext,
    ChatThemeContext,
    useChatStore,
    useChatTheme,
} from "./chatContexts";
import { getChatStatus, getChatPicture } from "./chatClientUtils";
import icons from "./fontello-embedded.module.css";

import React, { useRef, useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

export function Chat({ showBackButton, store, theme }) {
    return (
        <ChatThemeContext.Provider value={theme || {}}>
            <ChatStoreContext.Provider value={store}>
                <div className={styles.chat}>
                    <ChatContents showBackButton={showBackButton} />
                </div>
            </ChatStoreContext.Provider>
        </ChatThemeContext.Provider>
    );
}

const ChatContents = observer(({ showBackButton }) => {
    const Store = useChatStore();
    const { activeChat: chat } = Store;

    if (!chat) {
        return null;
    }

    const { title, messages } = chat;

    return (
        <>
            <ChatHeader
                picture={getChatPicture(chat)}
                title={title}
                status={getChatStatus(chat)}
                showBackButton={showBackButton}
            />
            <ChatMessages messages={messages} />
            <ChatFooter />
        </>
    );
});

const ChatHeader = observer(({ picture, title, status, showBackButton }) => {
    const Store = useChatStore();

    return (
        <div className={styles.header}>
            {showBackButton ? (
                <div
                    className={classnames(
                        styles.header__back,
                        icons["icon-left-open-big"]
                    )}
                    onClick={() => Store.unsetActiveChat()}
                />
            ) : null}
            {picture ? (
                <div
                    className={styles.header__picture}
                    style={{ backgroundImage: `url(${picture})` }}
                />
            ) : null}
            <div className={styles.header__details}>
                <div className={styles.header__title}>{title}</div>
                {status ? (
                    <div className={styles.header__status}>{status}</div>
                ) : null}
            </div>
        </div>
    );
});

const ChatMessages = observer(({ messages }) => {
    const scroller = useRef();
    const store = useChatStore();
    const { isScrolledToBottom } = store;

    const scrollToBottomIfNeeded = useCallback(() => {
        const elem = scroller.current;
        if (isScrolledToBottom) {
            elem.scrollTop = elem.scrollHeight - elem.clientHeight;
        }
    }, [isScrolledToBottom]);

    useEffect(() => scrollToBottomIfNeeded());

    const handleScroll = () => {
        const elem = scroller.current;
        const isScrolledToBottomNew =
            elem.scrollHeight - elem.scrollTop - elem.clientHeight < 1;
        store.setIsScrolledToBottom(isScrolledToBottomNew);
    };

    useEffect(() => {
        // This is mostly for handling keyboard open/close on mobile, which resizes the window
        window.addEventListener("resize", scrollToBottomIfNeeded);
        return () =>
            window.removeEventListener("resize", scrollToBottomIfNeeded);
    }, [scrollToBottomIfNeeded]);

    const theme = useChatTheme();

    return (
        <div
            className={styles.messagesWrapper}
            ref={scroller}
            style={{ backgroundImage: theme.bg ? `url(${theme.bg})` : null }}
            onScroll={handleScroll}
        >
            <div className={styles.messages}>
                {messages.map((message, i) => (
                    <ChatMessage
                        key={i}
                        message={message}
                        previousMessage={i > 0 ? messages[i - 1] : null}
                        onImagesLoaded={() => scrollToBottomIfNeeded()}
                    />
                ))}
            </div>
            <div
                className={classnames(
                    styles.messages__more,
                    isScrolledToBottom ? null : styles.visible
                )}
                onClick={() => store.setIsScrolledToBottom(true)}
            >
                <IconChevronsDown className={styles.messages__more__icon} />
            </div>
        </div>
    );
});

const IconChevronsDown = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
    >
        <polyline points="7 13 12 18 17 13" />
        <polyline points="7 6 12 11 17 6" />
    </svg>
);
