import styles from "./ChatFooter.module.css";
import { useChatStore } from "./chatContexts";
import icons from "./fontello-embedded.module.css";

import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

const INACTIVE_DEFAULT_TEXT_SINGLE =
    "You can't send messages to this person because they blocked you.";
const INACTIVE_DEFAULT_TEXT_GROUP =
    "You can't send messages to this group because you're no longer a participant.";

const ChatFooter = observer(() => {
    const Store = useChatStore();
    const { activeChat } = Store;

    return (
        <div className={styles.footer}>
            {activeChat.inactive ? (
                <ChatFooterInactive chat={activeChat} />
            ) : (
                <ChatFooterForm />
            )}
        </div>
    );
});

const ChatFooterForm = observer(() => {
    const Store = useChatStore();
    const { activePendingMessage, canSendMessage } = Store;

    const textbox = useRef(null);

    function handleSendClick() {
        if (!canSendMessage) {
            return;
        }

        Store.sendMessage();
        textbox.current.focus();
    }

    return (
        <>
            <input
                ref={textbox}
                className={styles.footer__input}
                type="text"
                placeholder="Type a message"
                value={activePendingMessage}
                onChange={(evt) =>
                    Store.setActivePendingMessage(evt.target.value)
                }
                onKeyPress={(evt) => evt.key === "Enter" && Store.sendMessage()}
            />
            <div
                className={classnames(
                    styles.footer__sendButton,
                    icons["icon-paper-plane"],
                    canSendMessage ? null : styles.disabled
                )}
                onClick={handleSendClick}
            />
        </>
    );
});

function ChatFooterInactive({ chat }) {
    const inactive = typeof chat.inactive === "boolean" ? {} : chat.inactive;
    const { text, fallback } = inactive;

    return (
        <div className={styles.inactive}>
            {text ||
                (chat.group
                    ? INACTIVE_DEFAULT_TEXT_GROUP
                    : INACTIVE_DEFAULT_TEXT_SINGLE)}
            {fallback ? (
                <a className={styles.inactive__fallback} href={fallback.url}>
                    {fallback.text}
                </a>
            ) : null}
        </div>
    );
}

export default ChatFooter;
