import styles from "./ChatMediaViewer.module.css";
import Image from "./Image"; // TODO: extract to package
import icons from "./fontello-embedded.module.css";

import VideoPlayer from "@faintlines/video-player";

import { observer } from "mobx-react-lite";
import classnames from "classnames";
import React, { useEffect } from "react";

// TODO: use ZoomableImage
// TODO: support text

export const ChatMediaViewer = observer(({ store }) => {
    const { activeMedia } = store;
    if (!activeMedia) {
        return null;
    }

    const { video, image } = activeMedia;

    return (
        <ChatMediaViewerContent
            video={video}
            image={image}
            onClose={() => store.hideActiveMedia()}
        />
    );
});

const ChatMediaViewerContent = ({ video, image, onClose }) => {
    const handleMediaClick = (evt) => evt.stopPropagation();

    useEffect(() => {
        const handleKeyPress = (evt) => {
            if (
                evt.key === "Escape" ||
                evt.key === "Esc" ||
                evt.keyCode === 27
            ) {
                onClose();
            }
        };
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [onClose]);

    return (
        <div className={styles.viewer} onClick={onClose}>
            <div
                className={classnames(styles.closeButton, icons["icon-cancel"])}
                onClick={onClose}
            />
            {image ? (
                <Image
                    className={styles.image}
                    src={image}
                    showSpinner
                    onClick={handleMediaClick}
                />
            ) : video ? (
                <VideoPlayer
                    url={video}
                    className={styles.video}
                    playsInline
                    autoPlay
                    controls
                    width="100%"
                    height="100%"
                    onClick={handleMediaClick}
                />
            ) : null}
        </div>
    );
};
